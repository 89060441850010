import * as React from 'react';
import { SVGProps } from 'react';
const SvgPageFlip = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 19.25c-4 0-7.25-2-7.25-2V5.75s3.75 1 7.25 1v12.5Zm0 0c4 0 7.25-2 7.25-2V5.75"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6.75c2.5 0 4.25-2 4.25-2V17S14.5 19 12 19"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPageFlip;
