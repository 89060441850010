import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaintbucket2 = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m7.642 18.377-2.007-2.008a3 3 0 0 1-.002-4.24L11 6.75 17.25 13l-5.363 5.374a3 3 0 0 1-4.245.003ZM19.25 18a1.25 1.25 0 1 1-2.5 0c0-.69.56-2.25 1.25-2.25s1.25 1.56 1.25 2.25ZM11.75 12.25v-7.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgPaintbucket2;
