import * as React from 'react';
import { SVGProps } from 'react';
const SvgBrickWall = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#brick-wall_svg__a)"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.75 4.75v3.5M12.75 12.5v3M19.25 8.25H4.75M19.25 15.75H4.75M19.25 12.25H4.75M8.75 8.75V12M8.75 15.75V19M15.25 8.75V12M15.25 15.75V19M4.75 6.75a2 2 0 0 1 2-2h10.5a2 2 0 0 1 2 2v10.5a2 2 0 0 1-2 2H6.75a2 2 0 0 1-2-2V6.75Z" />
    </g>
    <defs>
      <clipPath id="brick-wall_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBrickWall;
