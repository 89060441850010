import React from 'react';
import cx from 'classnames';
import { Whoosher } from '../Loaders';
import { ImageWithFallback } from '../ImageWithFallback';
import FallbackImage from '../../icons/iconic/svg/buildings.svg';
import * as styles from './AccountLogo.css';

export type Props = {
  accountName?: string | null;
  accountDomain?: string | null;
  size?: 'normal' | 'large';
  bordered?: boolean;
  notificationDot?: boolean;
  loading?: boolean;
  onClick?: () => void;
};

export const AccountLogo = ({
  accountDomain,
  accountName,
  size,
  loading,
  bordered,
  notificationDot,
  onClick,
}: Props) => {
  if (loading) {
    const whoosherSize = size === 'large' ? styles.largeSize : styles.normalSize;
    return <Whoosher height={whoosherSize} width={whoosherSize} />;
  }
  if (!accountDomain) {
    return null;
  }
  const clearbitSize = size === 'large' ? '120' : '40';
  return (
    <div
      className={cx(styles.logoWrapper, {
        [styles.logoWithNotification]: notificationDot,
        [styles.clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <ImageWithFallback
        className={cx(styles.logo, {
          [styles.logoLarge]: size === 'large',
          [styles.logoBordered]: bordered,
          [styles.logoWithNotification]: notificationDot,
        })}
        src={`//logo.clearbit.com/${accountDomain}?size=${clearbitSize}`}
        alt={`${accountName ?? accountDomain} company logo`}
        width="24px"
        height="24px"
        fallbackSrc={FallbackImage}
      />
    </div>
  );
};
